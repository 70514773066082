const state = {
  searchFilters: [],
  filterKeys: [],
  filterColumns: [],
  columnValues: [],
  formValue: {}
};

const getters = {
  filters: state => {
    var filter = "";

    for (let sf of state.searchFilters) {
      // eslint-disable-next-line no-console
      const value = sf.value;

      switch (sf.operator) {
        case "range":
          if (filter) {
            filter += " AND (" + sf.field + " >= " + value.start + ") AND (";
            filter += sf.field + " <= " + value.end + ")";
          } else {
            filter += "(" + sf.field + " >= " + value.start + ") AND (";
            filter += sf.field + " <= " + value.end + ")";
          }
          break;
        case "contains":
          if (filter) {
            filter += " AND (" + sf.field + " like %" + value + "%)";
          } else {
            filter += "(" + sf.field + " like %" + value + "%)";
          }
          break;
        case "equals":
          if (filter) {
            filter += " AND (" + sf.field + " = " + value + ")";
          } else {
            filter += "(" + sf.field + " = " + value + ")";
          }
          break;
        case "notequals":
          if (filter) {
            filter += " AND (" + sf.field + " != " + value + ")";
          } else {
            filter += "(" + sf.field + " != " + value + ")";
          }
          break;
        default:
          break;
      }
    }

    return filter;
  },
  fieldValue: state => {
    return {
      keys: state.filterKeys,
      selectedColumn: state.filterColumns,
      selectedColumnType: state.columnValues
    };
  },
  formValue: state => {
    return state.formValue;
  }
};

const mutations = {
  setFilters: (state, filters) => {
    state.searchFilters = filters;
  },
  setFieldValue: (state, values) => {
    state.filterKeys = values.keys;
    state.filterColumns = values.selectedColumn;
    state.columnValues = values.selectedColumnType;
  },
  setFormValue: (state, values) => {
    state.formValue = values;
  }
};

const actions = {
  filtered({ commit }, filters) {
    commit("setFilters", filters);
  },
  fieldValues({ commit }, values) {
    commit("setFieldValue", values);
  },
  formValues({ commit }, values) {
    commit("setFormValue", values);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
