import axios from "axios";
import router from "../router";

export default () => {
  var api = axios.create({
    baseURL: `https://lestarijayaraya.co.id:10433/api/v2`,
    // baseURL: `http://10.1.10.7:10090//api/v2`,
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-dreamfactory-api-key":
        "67c7b8eb420a4baa214dcf468659473e9f18a37980922969a8dde845ce5d7d0e"
    }
  });

  api.interceptors.response.use(
    function(response) {
      return response;
    },
    function(error) {
      if (error.response.status == 401) {
        window.localStorage.removeItem("user");
        router.push("/login");
      }
      return Promise.reject(error);
    }
  );

  return api;
};
