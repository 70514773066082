import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import shipment from "./modules/shipment";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: false
  },
  mutations: {
    startLoading: state => (state.loading = true),
    stopLoading: state => (state.loading = false)
  },
  actions: {
    load({ commit }) {
      commit("startLoading");
    },
    doneLoad({ commit }) {
      commit("stopLoading");
    }
  },
  modules: {
    auth,
    shipment
  }
});
