<template>
  <div style="height: 100%;">
    <a-layout id="main-component">
      <a-layout-sider :trigger="null" collapsible v-model="collapsed">
        <side-bar :collapsed="collapsed"></side-bar>
      </a-layout-sider>
      <a-layout>
        <a-layout-header style="background: #fff; padding: 0">
          <div>
            <a-icon
              class="trigger"
              :type="collapsed ? 'menu-unfold' : 'menu-fold'"
              @click="()=> collapsed = !collapsed"
            />
            <NavMenu></NavMenu>
          </div>
        </a-layout-header>
        <a-layout-content
          :style="{ margin: '24px 16px', padding: '24px', background: '#fff', minHeight: '280px' }"
        >
          <router-view />
        </a-layout-content>
      </a-layout>
    </a-layout>
  </div>
</template>

<script>
import SideBar from "@/components/navigations/SideBar.vue";
import NavMenu from "@/components/navigations/NavMenu.vue";
import router from "../router";

export default {
  name: "mainpage",
  components: {
    SideBar,
    NavMenu
  },
  mounted() {
    if (this.$route.path == "/") {
      router.push("/home");
    }
  },
  data() {
    return {
      collapsed: false
    };
  }
};
</script>

<style>
#main-component {
  min-height: 100%;
}

#main-component .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#main-component .trigger:hover {
  color: #1890ff;
}

#main-component .logo {
  margin: 16px;
  text-align: center;
}

.right-menu {
  margin-right: 24px !important;
}

.user-menu {
  cursor: pointer;
}

.menu-icon {
  font-size: 18px;
  padding: 4px;
}
</style>