import Api from "@/services/Api";

export default {
  login(loginData, callback, errCallback) {
    return Api()
      .post("/user/session", {
        ...loginData
      })
      .then(data => {
        callback(data);
      })
      .catch(error => {
        errCallback(error);
      });
  },
  logout(callback, errCallback) {
    const authUser = JSON.parse(window.localStorage.getItem("user"));
    if (authUser || authUser.session_token) {
      Api()
        .delete("/user/session?session_token=" + authUser.session_token)
        .then(() => {
          callback();
        })
        .catch(error => {
          errCallback(error);
        });
    } else {
      callback();
    }
  }
};
