<template>
  <div class="header-right">
    <a-badge :count="0" class="right-menu">
      <a-icon type="bell" class="menu-icon user-menu"/>
    </a-badge>
    <a-dropdown v-model="visible" class="user-menu right-menu ant-dropdown-link">
      <a-avatar icon="user"/>
      <a-menu slot="overlay">
        <a-menu-item key="1">
          <a-icon type="profile"/>Profile
        </a-menu-item>
        <a-menu-item key="2" @click="doLogout()">
          <a-icon type="logout"/>Logout
        </a-menu-item>
      </a-menu>
    </a-dropdown>
  </div>
</template>

<script>
// import axios from "axios";
import router from "../../router";
import LoginService from "@/services/LoginService";

export default {
  data() {
    return {
      visible: false
    };
  },
  methods: {
    doLogout() {
      LoginService.logout(
        function() {
          window.localStorage.removeItem("user");
          router.push("/login");
        },
        function(error) {
          // eslint-disable-next-line no-empty
          if (error.response) {
          }
        }
      );
    }
  }
};
</script>

<style>
.header-right {
  float: right;
  margin-right: 32px;
}
</style>