import router from "../../router";
import LoginService from "@/services/LoginService";

const state = {
  loggingIn: false,
  loginError: null,
  loginSuccessful: false
};

const getters = {};

const mutations = {
  loginStart: state => (state.loggingIn = true),
  loginStop: (state, errorMessage) => {
    state.loggingIn = false;
    state.loginError = errorMessage ? errorMessage.message : null;
    state.loginSuccessful = !errorMessage;
  }
};

const actions = {
  doLogin({ commit }, loginData) {
    commit("loginStart");

    LoginService.login(
      loginData,
      function(data) {
        var user = data.data;
        if (user.session_token) {
          localStorage.setItem("user", JSON.stringify(user));
        }

        commit("loginStop", null);
        router.push("/");
      },
      function(error) {
        if (error.response) {
          commit("loginStop", error.response.data.error);
        }
      }
    );
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
