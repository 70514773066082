<template>
  <div>
    <div :class="collapsed ? 'avatar-box hidden' : 'avatar-box'">
      <a-avatar shape="square" :size="96" :src="images.logo"/>
      <p class="logo-title">PT. LESTARI JAYA RAYA</p>
      <p class="logo-subtitle">Customer Web Tracking</p>
    </div>
    <div :class="collapsed ? 'logo-box' : 'logo-box hidden'">
      <img :src="images.logonobox">
    </div>
    <a-menu
      :defaultSelectedKeys="selectedMenu"
      mode="inline"
      theme="dark"
      :inlineCollapsed="collapsed"
    >
      <!-- eslint-disable-next-line vue/no-unused-vars -->
      <a-menu-item v-for="(menu, index) in menus" :key="menu.key" @click="onClick($event,menu)">
        <a-icon :type="menu.icon"/>
        <span>{{menu.title}}</span>
      </a-menu-item>
    </a-menu>
  </div>
</template>

<script>
import router from "../../router";

export default {
  props: {
    collapsed: {}
  },
  data() {
    return {
      menus: [
        {
          key: "homepage",
          title: "Home",
          url: "/home",
          icon: "home"
        },
        {
          key: "myshipment",
          title: "My Shipment",
          url: "/myshipment",
          icon: "file-text"
        }
        // {
        //   key: "mypickup",
        //   title: "My Pickup Request",
        //   url: "/mypickup",
        //   icon: "inbox"
        // }
      ],
      images: {
        logo: require("@/assets/logo_small_gray.png"),
        logonobox: require("@/assets/logo_nobox.png")
      }
    };
  },
  methods: {
    onClick: function(event, menu) {
      if (menu.key == "myshipment") {
        router.push(menu.url + "/index");
      } else {
        router.push(menu.url);
      }
    }
  },
  computed: {
    selectedMenu() {
      if (this.$route.name.includes("index")) {
        return [this.$route.name.replace("index", "")];
      } else if (this.$route.name.includes("detail")) {
        return [this.$route.name.replace("detail", "")];
      } else {
        return [this.$route.name];
      }
    }
  }
};
</script>

<style>
.avatar-box {
  text-align: center;
  padding: 20px;
  background: #2c3840;
}

.logo-box {
  text-align: center;
  padding: 20px;
  background: #616161;
}

.hidden {
  display: none;
}

.logo-title {
  color: white;
  margin-top: 10px;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 13px;
  color: #8aa0ae;
}

.logo-subtitle {
  color: white;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 12px;
  color: #8aa0ae;
}
</style>