import Vue from "vue";
import Router from "vue-router";
import Main from "./views/Main.vue";

var moment = require("moment");

Vue.use(Router);

const routes = [
  {
    name: "home",
    path: "/",
    component: Main,
    meta: { requiresAuth: true },
    children: [
      {
        name: "homepage",
        path: "/home",
        component: () => import("./pages/Home.vue"),
        meta: { requiresAuth: true }
      },
      {
        name: "myshipment",
        path: "/myshipment",
        component: () => import("./pages/MyShipment.vue"),
        meta: { requiresAuth: true },
        children: [
          {
            name: "myshipmentindex",
            path: "/myshipment/index",
            component: () => import("./components/shipment/ShipmentIndex.vue"),
            meta: { requiresAuth: true }
          },
          {
            name: "myshipmentdetail",
            path: "/myshipment/detail",
            component: () => import("./components/shipment/ShipmentDetail.vue"),
            meta: { requiresAuth: true }
          }
        ]
      },
      {
        name: "mypickup",
        path: "/mypickup",
        component: () => import("./pages/MyPickup.vue"),
        meta: { requiresAuth: true }
      }
    ]
  },
  {
    name: "login",
    path: "/login",
    component: () => import("./pages/Login.vue")
  }
];

const router = new Router({
  base: process.env.BASE_URL,
  mode: "history",
  routes: routes
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    const authUser = JSON.parse(window.localStorage.getItem("user"));
    if (!authUser || !authUser.session_token) {
      next({ name: "login" });
    } else {
      // Token Expiration Check
      const lastLoginDate = moment(authUser.last_login_date);
      const nowDate = moment();

      var duration = moment.duration(nowDate.diff(lastLoginDate));
      var hours = duration.asHours();

      if (hours > 8) {
        next({ name: "login" });
      } else {
        next();
      }
    }
  } else {
    next();
  }
});

export default router;
